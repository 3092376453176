import './App.css';

function App() {
    return (
        <div>

            <h1><i className="fas fa-flask"></i> 91 developments</h1>

            <header>
                <h2>We connect <br/>health & technology</h2>

                <p>A multidisciplinary team comprising of health practitioners & technology specialists </p>

                <h3><i className="fas fa-mobile"></i>&nbsp;&nbsp;Our Products</h3>
                <p className="small">Check back soon! We are currently in stealth mode whilst we build our products</p>

                <div className="appicons">
                    <div className="appicon"></div>
                    <div className="appicon"></div>
                </div>

            </header>

            <footer>
                <i className="far fa-envelope"></i> Get in touch - <a
                href="mailto:hello@91developments.com">hello@91devlopments.com</a>
            </footer>
        </div>
    );
}

export default App;
